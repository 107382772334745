.position_auth_popup {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.body_popup {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 450px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
}
.body_popup h2 {
    font-weight: 700;
    font-size: 20.6883px;
    line-height: 25px;
    color: #000000;
    margin: 20px 0;
}
.body_popup p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3px;
    color: #748194;
}

.button_confirm {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 6px;
    width: 85%;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 30px;
}
.button_cancel {
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 6px;
    width: 85%;
    margin-bottom: 20px;
    color: #5d6e82;
    background: #dfe2e4;
    margin-right: 10px;
    margin-top: 30px;
}

@media screen and (max-width: 450px) {
    .body_popup {
        max-width: 94%;
        padding: 30px 25px;
    }
}
