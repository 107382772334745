* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
a {
    text-decoration: none;
    outline: none;
}
header {
    position: absolute;
    height: 50px;
    right: 0;
    width: 100%;
    background-color: #2b0078;
}
table td,
table th {
    white-space: nowrap;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* BG Colors */
.bg-auth {
    background-color: #162142 !important;
}
.bg-main {
    background-color: #2b0078 !important;
}
.bg-primary {
    background-color: #7730f6 !important;
}
.bg-secondary {
    background-color: #748194 !important;
}
.bg-badge-secondary {
    background-color: #848484 !important;
}
.bg-success {
    background-color: #00d27b !important;
}
.bg-success-badge {
    background-color: #c9fcbd !important;
}
.bg-success {
    background-color: #00d27b !important;
}
.bg-silver {
    background-color: #b6c2d2;
}
.bg-gold {
    background-color: #efc11e;
}
.bg-review {
    background-color: #fec053 !important;
}
.bg-info {
    background-color: #27bcfe !important;
}
.bg-info-2 {
    background-color: #4aa3bb !important;
}
.bg-warning {
    background-color: #f68f57 !important;
}
.bg-danger {
    background-color: #e63756 !important;
}
.bg-danger-2 {
    background-color: #bf2843 !important;
}
.bg-danger-badge {
    background-color: #ffcdd6 !important;
}
.bg-light {
    background-color: #f9fafe !important;
}
.bg-dark {
    background-color: #0b1828 !important;
}
.bg-white {
    background-color: #ffffff !important;
}
.bg-transparent {
    background-color: transparent;
}

.text-right {
    text-align: right;
}

button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

/* BTN Sizes */
.btn-small {
    padding: 5px 13px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}
.btn-regular {
    padding: 5px 17px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}
.btn-large {
    padding: 5px 22px;
    border-radius: 4px;
    font-weight: 400;
    font-size: 19px;
    line-height: 19px;
}

/* Badges */
.badge {
    display: inline-block;
    padding: 3px 9px;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    border-radius: 3px;
    margin-left: 10px;
    vertical-align: middle;
}
.badge-table {
    display: inline-block;
    color: white;
    padding: 5px 9px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    border-radius: 3px;
    margin-left: 10px;
    vertical-align: middle;
}
.badge-pill {
    border-radius: 10px;
}

/* Text Colors */
.text-main {
    color: #2b0078;
}
.text-primary {
    color: #7730f6 !important;
}
.text-secondary {
    color: #748194;
}
.text-success {
    color: #00d27b;
}
.text-success-badge {
    color: #5bc142;
}
.text-silver {
    color: #b6c2d2;
    font-weight: 600;
}
.text-info {
    color: #27bcfe;
}
.text-warning {
    color: #f68f57;
}
.text-danger {
    color: #e63756;
}
.text-light {
    color: #f9fafe;
}
.text-dark {
    color: #0b1828;
}
.text-black {
    color: #162142;
}
.text-white {
    color: #ffffff;
}

.bold-700 {
    font-weight: 700;
}

/* Borders */
.border-1-primary {
    border: 1px solid #7730f6;
}
.border-1-secondary {
    border: 1px solid #748194;
}
.border-1-success {
    border: 1px solid #00d27b;
}
.border-1-info {
    border: 1px solid #27bcfe;
}
.border-1-warning {
    border: 1px solid #f68f57;
}
.border-1-danger {
    border: 1px solid #e63756;
}
.border-1-light {
    border: 1px solid #f9fafe;
}
.border-1-dark {
    border: 1px solid #0b1828;
}
.border-1-white {
    border: 1px solid #ffffff;
}

.border-radius-24 {
    border-radius: 24px;
}
.border-circle {
    border-radius: 50%;
}

/* box-Shadows */
.box-shadow-button {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
        0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 2px 5px rgba(43, 45, 80, 0.08),
        0px 0px 0px 1px rgba(43, 45, 80, 0.1);
}
/* FORM */
label {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}
.form-check {
    margin-bottom: -2px;
}

input {
    border: 1px solid #e5e5e5;
}
input::placeholder {
    color: #b6c2d2 !important;
}
input[type="checkbox"] {
    accent-color: #7730f6;
}
.form-check-input:checked {
    background-color: #7730f6;
}

input[type="password"] {
    padding-right: 35px !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
input[type="range"] {
    /* -webkit-appearance: none; */
    outline: none;
    width: 100%;
    border: none;
}
input[type="range"]:focus {
    outline: none;
    border: none;
    background-color: transparent;
}
input[type="range"]::-webkit-slider-thumb {
    box-shadow: 0px 0px 1px #7730f6;
    border: 3px solid #7730f6;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: #7730f6;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
}
input[type="range"]::-ms-thumb {
    box-shadow: 0px 0px 1px #7730f6;
    border: 3px solid #7730f6;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: #7730f6;
    cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
    box-shadow: 0px 0px 1px #7730f6;
    border: 3px solid #7730f6;
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: #7730f6;
    cursor: pointer;
}
/* @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="range"] {
        overflow: hidden;
        width: 100%;
        -webkit-appearance: none;
        background-color: #ffffff00;
        outline: none;
    }

    input[type="range"]::-webkit-slider-runnable-track {
        height: 20px;
        -webkit-appearance: none;
        color: #7930f600;
        margin-top: -1px;
        outline: none;
    } */

/* input[type="range"]::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 20px;
        cursor: ew-resize;
        background: #434343;
        box-shadow: -80px 0 0 80px #20b41b;
        outline: none;
    } */
/* } */
input[type="range"]::-moz-range-progress {
    background-color: #7730f6;
    height: 8px;
    border-radius: 20px;
    outline: none;
}
input[type="range"]::-moz-range-track {
    background-color: #35313183;
    outline: none;
    height: 8px;
    border-radius: 20px;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #7730f6;
    outline: none;
}
/* input[type="range"]::-ms-fill-upper {
    background-color: #e01010;
} */

.show_header {
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 200 !important;
    top: 50px !important;
}
.show_language {
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 200 !important;
    bottom: 50px !important;
}
.unset_scroll {
    overflow-y: unset !important;
}

/* layout */
.laybase {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
    display: flex;
}
.lay-left {
    flex-basis: 50%;
    background-color: #2b0078;
}
.lay-left-mobile {
    flex-basis: 50%;
    display: none;
    background-color: #edf2f8;
}
.lay-right {
    flex-basis: 50%;
    background-color: #edf2f8;
    position: relative;
}
.head-fake {
    height: 50px;
    background-color: #2b0078;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
}
#phone_register {
    position: relative;
}
.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-left: 10px;
}
.PhoneInputInput:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.form-check-input:checked {
    border: none !important;
}

.fullpage_container {
    width: 100%;
    height: 100vh;
    background-color: #edf2f8;
    overflow: hidden;
}

.main_container {
    width: 100%;
    display: flex;
    height: 100%;
}
.main_container_box {
    width: calc(100% - 280px);
    position: relative;
}
.main_content {
    padding: 30px;
    margin-top: 50px;
    overflow-y: auto;
    width: 100%;
    position: relative;
    height: calc(100% - 50px);
}

.verified_password,
.registration_verified_password,
.registration_company_verified_password {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}
.verified_number_phone {
    position: absolute;
    right: 5px;
    top: 50px;
    transform: translateY(-50%);
}

/* *GO Sign */
#popupGoSign {
    display: none;
}
.body_popup {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 450px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
}
.body_popup h2 {
    font-weight: 700;
    font-size: 20.6883px;
    line-height: 25px;
    color: #000000;
    margin: 20px 0;
}
.body_popup p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 3px;
    color: #748194;
}

.button_confirm {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 6px;
    width: 85%;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 30px;
}
.button_cancel {
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 6px;
    width: 85%;
    margin-bottom: 20px;
    color: #5d6e82;
    background: #dfe2e4;
    margin-right: 10px;
    margin-top: 30px;
}

@media screen and (max-width: 450px) {
    .body_popup {
        max-width: 94%;
        padding: 30px 25px;
    }
}

/** pULSE ANIMATION */
.blob {
    background: black;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    margin: 10px;
    height: 14px;
    width: 14px;
    transform: scale(1);
}
.blob.red {
    border: 2px solid white;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
    top: -14px;
    right: 5px;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

/** Sidebar */
#sidebar_content,
#sidebar_content_mobile {
    position: relative;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px);
}
#sidebar_content {
    height: 100%;
}
#sidebar {
    transition: all 0.4s;
}
.sidebar_scroll,
.sidebar_scroll_mobile {
    flex-grow: 2;
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 20px;
}
.sidebar_scroll::-webkit-scrollbar,
.sidebar_scroll_mobile::-webkit-scrollbar {
    width: 5px;
}
.sidebar_scroll::-webkit-scrollbar-thumb,
.sidebar_scroll_mobile::-webkit-scrollbar-thumb {
    background-color: #959ba2;
    border-radius: 10px;
}

#search {
    height: 32px;
    border: 1px solid #555;
    width: 300px;
    margin-left: 5px;
    padding-left: 40px;
    background: #edf2f8
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
        no-repeat 13px center;
    transition: all 0.6s;
}
.arrow {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    transition: all 0.5s;
}
.arrow.active,
.rotate {
    transform: rotate(180deg);
}
.error_input {
    color: rgb(136, 17, 17);
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 10px;
    margin-top: 2px;
}

.menu_open {
    height: 100%;
    background-color: #2b0078;
    position: fixed;
    top: 0%;
    left: 0;
    width: 280px;
    transition: all 0.5s ease-in-out;
    margin-top: 50px;
    z-index: 300;
    overflow: hidden;
}
.menu_closed {
    height: 100%;
    background-color: #2b0078;
    position: fixed;
    top: 0%;
    left: 0;
    width: 0px;
    transition: all 0.5s ease-in-out;
    margin-top: 50px;
    z-index: 300;
    overflow: hidden;
}

/** ProfileMenu */
.success_call {
    width: 80px !important;
    background-color: green !important;
    border-radius: 3px !important;
}
.error_call {
    width: 80px !important;
    background-color: rgb(128, 11, 0) !important;
    border-radius: 3px !important;
}

#profile_menu a.active li {
    border-left: 3px solid #7730f6;
}
#profile_menu a.active li svg {
    margin-left: 17px;
}
#profile_menu a.active li svg path {
    fill: #7730f6;
}
#profile_menu a.active li span {
    color: #7730f6;
}

#email_profile .confirmed {
    color: #54c104;
}
#email_profile .unconfirmed {
    color: #e63756;
}
#devices table td,
#notification table td {
    padding: 1rem 0.5rem !important;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #6a7786;
}
#devices table th {
    background: #f8fafd;
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    color: #4d5969;
    text-transform: uppercase;
    border-bottom: none;
}
#devices tbody,
#notification tbody {
    border-top: none;
}
#notification .form-check-inline {
    margin-left: 0;
    margin-right: 0;
    font-size: 20px;
}

#notification thead tr {
    background: #f8fafd;
}
#notification table th {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4d5969;
    text-transform: uppercase;
    border-bottom: none;
}

#upload-photo,
#upload-banner {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 1px;
}
#label_upload_avatar,
#label_upload_banner {
    cursor: pointer;
}
#label_upload_banner {
    padding: 5px 7px;
}

#two_step_verified,
#kyc_confirm {
    display: none;
}
#layover {
    background: rgba(26, 26, 26, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    display: none;
}
.pagination {
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.pagination div.magic {
    position: fixed;
    bottom: 30px;
    display: flex;
    justify-content: space-between;
}
.pagination button,
.pagination_right button {
    border: none;
    background-color: transparent;
    margin: 0 5px;
    font-size: 20px;
    font-weight: bold;
}
.pagination button:hover,
.pagination_right button:hover {
    color: #7730f6;
}
.pagination button:disabled,
.pagination_right button:disabled {
    color: transparent;
}
.pagination span strong,
.pagination_right span strong {
    background: #dfe2e4;
    border-radius: 6.66667px;
    padding: 5px 15px;
}

.pagination_right {
    margin-top: 10px;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 20px;
}
.pagination_right div.magic {
    display: flex;
    justify-content: space-between;
}

#setting_referral table {
    background-color: red !important;
}

#list_member .pagination {
    justify-content: center;
}

/** Contract */
.show_folder {
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 200 !important;
    top: 30px !important;
}
.setting_sign.show {
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 200 !important;
    top: 40px !important;
}
.setting_sign.show .more_icon_sign {
    color: #7730f6;
}
.setting_sign.show .more_icon_sign svg {
    transform: rotate(180deg);
}
#sign_contract hr {
    margin: 5px 0 7px;
}
/* * Referral */
.rettangolo_laterale_portrait {
    height: 24px;
    width: 14px;
    background: #c4c4c4;
}
.rettangolo_centrale_portrait {
    height: 24px;
    width: 14px;
    background: #162142;
}
.rettangolo_laterale_squared {
    height: 24px;
    width: 10px;
    background: #c4c4c4;
}
.rettangolo_centrale_squared {
    height: 24px;
    width: 22px;
    background: #162142;
}
.rettangolo_centrale_landscape {
    height: 24px;
    width: 32px;
    background: #162142;
}

/** Club PAGEMEMBER */
.show_folder_club {
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 200 !important;
    top: -30px !important;
}

.row_small {
    display: flex;
    height: 70px;
}
.row_none {
    display: none;
}
.row_none_show {
    display: block;
}
#change_box_rewards {
    display: none;
}

@media screen and (max-width: 1200px) {
    #sidebar {
        display: none;
    }
    .lay-left {
        display: none;
    }
    .lay-left-mobile {
        display: block;
    }
    .main_container_box {
        width: 100%;
    }
    #search {
        display: none;
    }
}

@media screen and (min-width: 1200px) {
    .menu_open {
        display: none;
    }
    .menu_closed {
        display: none;
    }
}
@media screen and (max-width: 450px) {
    .pagination {
        display: block;
    }
    #list_member .pagination {
        display: flex;
        justify-content: center;
    }
}

.new-container {
    width: 100%;
    padding-right: var(0.75rem);
    padding-left: var(0.75rem);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .new-container,
    .new-container-sm {
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .new-container,
    .new-container-md,
    .new-container-sm {
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .new-container,
    .new-container-lg,
    .new-container-md,
    .new-container-sm {
        max-width: 100%;
    }
}
@media (min-width: 1200px) {
    .new-container,
    .new-container-lg,
    .new-container-md,
    .new-container-sm,
    .new-container-xl {
        max-width: 1240px;
    }
}
@media (min-width: 1400px) {
    .new-container {
        max-width: 1420px;
    }
}
/* Aggiungere a tutti i bottoni un hover */
