.referral_analytics {
    margin-bottom: 80px;
}
.referral_analytics h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #162142;
    margin-bottom: 20px;
}
.filter_analytics button {
    border: none;
    margin-right: 10px;
    margin-bottom: 8px;
    background: #ffffff;
    border-radius: 11px;
    padding: 8px 18px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #162142;
}
.statistics {
    background: #ffffff;
    border-radius: 20px;
    margin: 30px 1px;
}
.statistics .title_box {
    font-weight: 600;
    font-size: 13.8667px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 5px;
}
.statistics .number_box {
    font-weight: 700;
    font-size: 24px;
    color: #000000;
}
.statistics .increment_box {
    font-weight: 600;
    font-size: 13px;
    color: #54c104;
    margin-top: -5px;
}
.referral_analytics p {
    font-weight: 400;
    font-size: 13.8667px;
    line-height: 21px;
    color: #162142;
}
