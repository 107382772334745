.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.card_info_text {
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 15.18px;
    line-height: 19px;
    color: #1e2022;
}
