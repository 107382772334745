.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.login {
    width: 100%;
    width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px 20px 10px;
}
.card .header {
    text-align: center;
}
.header .signUp {
    line-height: 15px;
    margin-bottom: 20px;
}
.card .forgotPasswordBox {
    position: relative;
}
.forgotPassword {
    position: absolute;
    right: 0;
    top: 0;
}
.error {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--error);
}
@media screen and (max-width: 460px) {
    .login {
        width: 100%;
    }
}
