.referral_hero {
    margin-bottom: 80px;
}
.add_user {
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #162142;
}
.add_user:hover {
    color: #7730f6;
}
.box_title {
    margin-right: auto;
    margin-top: 30px;
}
.referral_hero h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36.9778px;
    line-height: 55px;
    color: #162142;
}
.box_referral {
    background: #f9fafd;
    border-radius: 24px;
    padding: 25px 35px !important;
    max-width: 600px;
    margin: auto;
}
.link_referral span {
    font-weight: 600;
    font-size: 13.8967px;
    line-height: 21px;
    color: #8c39ab !important;
    cursor: pointer;
}
.link_referral span:after {
    content: ">";
    margin-left: 3px;
}
.box_referral_values {
    background: #5009cf;
    border-radius: 11px;
    margin: 0 0 10px;
    margin-top: 20px !important;
    color: white;
}
.box_referral_values .data_values {
    padding: 8px 13px;
}
.box_referral_values .data_values div {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
}
.box_referral_values .data_values div:nth-child(2) {
    font-weight: 600;
    font-size: 25.0141px;
    line-height: 38px;
    color: #ffffff;
}
.referral_id_box {
    min-height: 76px;
    background: #e8ecef;
    border-radius: 11px;
    margin: 0 0 10px;
    padding: 5px;
    margin-top: 20px;
    word-break: break-all;
}
.referral_id_box .data_values {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 18.0657px;
    line-height: 27px;
    color: #404445;
}
.referral_id_box .data_values:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 900;
    font-size: 14.5915px;
    line-height: 22px;
    color: #404445;
}
.referral_id_box .data_values:nth-child(2) img {
    width: 20px;
    margin-left: 9px;
    cursor: pointer;
}
.referral_id_box .data_values_link {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 900;
    font-size: 12px;
    line-height: 22px;
    color: #404445;
    text-align: right;
}
.referral_id_box .data_values_link img {
    width: 20px;
    margin-left: 9px;
    cursor: pointer;
}
.button_box {
    margin: 0 1px;
    color: white;
    margin-top: 20px;
}
.button_box .data_values {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #5009cf;
    border-radius: 11px;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    color: #ffffff;
    cursor: pointer;
}
.button_box .data_values:nth-child(2) {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e8ecef;
    border-radius: 11px;
    font-weight: 600;
    font-size: 17px;
    line-height: 27px;
    cursor: pointer;
}
.tooltip {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}
.tooltip svg {
    height: 20px;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99;
    bottom: 140%;
    left: 1px;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.box_referral_link {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 575px) {
    .box_referral {
        padding: 25px 15px;
    }
}
@media screen and (max-width: 470px) {
    .box_referral_values {
        text-align: center;
        padding: 10px 0;
    }
    .link_referral {
        display: block;
    }
    .data_values {
        margin-bottom: 10px;
    }
}
