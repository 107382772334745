.terms {
    padding: 10px 20px;
}
.terms h1 {
    font-weight: 600;
    font-size: 36.9778px;
    line-height: 55px;
    color: #162142;
}
.terms ul {
    list-style: none;
}
.terms li {
    padding-bottom: 10px;
}
.terms h4,
.terms h6,
.terms h3 {
    font-weight: 600;
}
.terms h3 {
    text-transform: uppercase;
    font-size: 22px;
}
.terms ul.index {
    list-style: decimal;
}
.terms ul.index li {
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 5px;
    font-size: 15px;
}
.terms ul.index li a {
    color: #162142;
}
.terms ul.index li:hover a {
    color: #620659;
}
.letterList {
    list-style: upper-alpha !important;
}
.letterList li {
    padding-bottom: 10px;
}
