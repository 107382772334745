@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

:root {
    --main-bg-color: #2b086a;
    --main-color: #7730f6;

    --white-color: #fff;
    --text-auth: #748194;
    --dark-text: #000;

    --error: #ff3333;
    --error-bg: #ffcccc;
    --success: #00cc00;
}

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    /* scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: var(--main-color) rgba(255, 255, 255, 0) !important; */
}

/* + SCROLLBAR */
/* ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background-clip: content-box;
    background-color: rgba(0, 0, 0, 0);
    width: 2px;
}

::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-color: var(--main-color);
    border-radius: 2px;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
} */
/* + NEW */
/* a {
    color: var(--main-color);
    text-decoration: none;
    outline: none;
}
a:hover {
    color: var(--main-bg-color);
}

button {
    background-color: var(--main-color);
    color: var(--white-color);
}
button:hover {
    background-color: var(--main-bg-color);
} */
