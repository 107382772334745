.position_auth_popup {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.body_popup {
    background: #ffffff;
    border-radius: 10px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px;
    width: 450px;
}
.body_popup h2 {
    font-weight: 700;
    font-size: 20.6883px;
    line-height: 25px;
    color: #000000;
    margin: 20px 0;
}
.body_popup p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 3px;
}

.two_factor_box div {
    justify-content: center;
    display: flex;
    margin-bottom: 25px;
    margin-top: 25px;
    flex-wrap: wrap;
}
.two_factor_box input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 24px;
    margin: 5px 6px;
    text-align: center;
    text-transform: uppercase;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: #fff;
    background-clip: border-box;
    background-clip: padding-box;
}

.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 6px;
    width: 85%;
    margin-bottom: 30px;
}
.p_two_factor {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 0 30px;
    color: #b6c2d2 !important;
}
.p_two_factor_pop {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 0 30px;
    color: #9a1313 !important;
    cursor: pointer;
}
.p_two_factor_pop:hover {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    padding: 0 30px;
    color: #7730f6 !important;
    cursor: pointer;
}

@media screen and (max-width: 450px) {
    .body_popup {
        max-width: 94%;
        padding: 30px 25px;
    }
}
