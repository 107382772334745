.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
    position: relative;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.card_info_text {
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 15.18px;
    line-height: 19px;
    color: #1e2022;
}
.card_title_inner {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #838e9a;
}
.position_button {
    display: flex;
    justify-content: flex-end;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px 0px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 15px;
    transition: width 0.3s;
    position: relative;
    width: 170px;
    height: 40px;
}
.button span {
    display: block;
    width: 100%;
}
.button * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;
}
.saved_text {
    opacity: 0;
    /* transition-delay: 0.3s; */
    transition: 0.1s;
}
.coming_soon_nofification {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90;
}
