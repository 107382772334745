.box_change_ratio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    height: 500px;
    overflow-y: scroll;
    border-radius: 10px;
    z-index: 99;
}
.box_change_ratio_inner {
    background-color: white;
    padding: 20px 30px 30px 30px;
    border-radius: 10px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
    position: relative;
}
.info_Ratio_rewards h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #162142;
}
.info_Ratio_rewards span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #162142;
}
.box_data_user {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
}
.avatar_and_info {
    display: flex;
}
.ratio_reward {
    margin-right: 30px;
}
.avatar_and_info .avatar {
    width: 81px;
    height: 81px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #7730f6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.avatar_and_info .avatarPlaceholder {
    width: 81px;
    height: 81px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #7730f6;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25px;
}
.avatar_and_info .info small,
.ratio_reward small {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #748194;
    text-transform: uppercase;
    margin-bottom: 7px;
}
.avatar_and_info .info .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #162142;
}
.avatar_and_info .info .username {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #7730f6;
}
.avatar_and_info .info .id_user {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #a6b2be;
}
.ratio_reward .percentage {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #1e1f25;
}
.info_attention {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #162142;
}
.title_choose {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #162142;
}
.checkbox_label {
    display: flex;
    align-items: center;
    margin: 7px 0;
}
.label_rewards {
    font-weight: 400;
    font-size: 11.1891px;
    line-height: 15px;
    color: #0b1828;
    margin-left: 10px;
    margin-bottom: 0;
}

.label_box {
    display: flex;
    align-items: center;
}
.col_form_label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14.18px;
    line-height: 18px;
    padding: 10px 5px;
}
.range_input {
    border: none !important;
    outline: none !important;
}
.input_set_percentage {
    width: 80px;
}
.close_button {
    position: absolute;
    top: 20px;
    right: 25px;
    cursor: pointer;
}
.discard {
    align-items: center;
    padding: 5px 13px;
    background: #dfe2e4;
    border-radius: 3px;
    outline: none;
    border: none;
    font-weight: 500;
    font-size: 16.0442px;
    line-height: 24px;
    color: #5d6e82;
    margin-right: 10px;
}
.confirm {
    padding: 5px 13px;
    background: #7730f6;
    border-radius: 3px;
    outline: none;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 16.0442px;
    line-height: 24px;
}
.inputBox {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

@media screen and (max-width: 750px) {
    .box_change_ratio {
        max-width: 500px;
    }
    .box_data_user {
        flex-direction: column;
        gap: 10px;
    }
    .ratio_reward {
        margin-right: 0;
        text-align: center;
    }
    .inputBox {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
    }
    .avatar_and_info {
        flex-direction: column;
        align-items: center;
        gap: 5px;
        text-align: center;
        margin-top: 15px;
    }
    .avatar_and_info .avatar {
        margin-right: 0;
    }
    .avatar_and_info .avatarPlaceholder {
        margin-right: 0;
    }
}
@media screen and (max-width: 510px) {
    .box_change_ratio {
        max-width: 320px;
    }
}
