.box_step,
.box_step_confirm {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14.9915px;
    color: black;
}
.box_step::after {
    content: "";
    display: block;
    margin-top: 5px;
    border-bottom: 1px solid #cccdd0;
    flex-grow: 2;
    margin-left: 10px;
}
.box_step > div,
.box_step_confirm > div {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    background: #e7e8eb;
    color: #4d5969;
    margin-right: 10px;
}
.active .box_step,
.active .box_step_confirm {
    color: #7730f6;
}
.active .box_step > div,
.active .box_step_confirm > div {
    background: #7730f6;
    color: white;
}
.avatar_upload {
    background-color: #e7eaf2;
    overflow: hidden;
    height: 70px;
    width: 70px;
    text-align: center;
    line-height: 70px;
    border-radius: 50%;
    font-size: 35px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.avatar_upload svg path {
    fill: #bec5ce;
}

.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 20px;
    position: relative;
}
.card_profile_add {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 20px;
    position: relative;
    padding-top: 170px;
}
.card_profile_add_finish {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    position: relative;
    padding: 50px 24px 24px;
    min-width: 320px;
    max-width: 600px;
    margin: auto;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.label_box {
    display: flex;
    align-items: center;
}
.col_form_label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14.18px;
    line-height: 18px;
    padding: 10px 5px;
}
.form_control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form_control {
    display: block;
    width: 100%;
    padding: 9px 0.75rem;
    color: #1e2022;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.751391px solid #eef0f5;
    appearance: none;
    border-radius: 3.75696px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.remember_billing {
    font-weight: 500;
    font-size: 12.9086px;
    line-height: 12px;
    color: #5d6e82;
}
.optional_set {
    color: #c4c4c4;
}
.position_button {
    display: flex;
    justify-content: flex-end;
}
.position_doublue_button {
    display: flex;
    justify-content: space-between;
}
.position_doublue_button_finish {
    display: flex;
    justify-content: center;
}
.button {
    background: #7730f6;
    display: flex;
    align-items: center;
    border-radius: 3px;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
}
.prev_button {
    border: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    background: none;
    color: #5d6e82;
}
.prev_button svg {
    margin-right: 10px;
    height: 12px;
}
.button svg {
    margin-left: 10px;
    height: 12px;
}
.prev_button:hover {
    color: #7730f6;
}
.range_input {
    border: none !important;
    outline: none !important;
}
.input_set_percentage {
    width: 80px;
}
.banner_confirmation_user {
    height: 120px;
    background-color: rgb(253, 253, 253);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.circle_photo {
    height: 90px;
    width: 90px;
    background-color: #e7eaf2;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    border: 3px solid #ffffff;
    cursor: pointer;
}
.label_confirmed_user {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #6d7a88;
    text-align: right;
}
.text_confirmed_user {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.mineAndMember {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .mineAndMember {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    .menuPoint {
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }
}
@media screen and (max-width: 576px) {
    .label_confirmed_user {
        text-align: center;
    }
    .text_confirmed_user {
        text-align: center;
    }
    .mineAndMember {
        margin-top: 20px;
    }
}
