.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.surveySent {
    width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.container {
    padding: 20px;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    border: none;
    width: 100%;
    font-weight: 500;
    font-size: 12.8247px;
    line-height: 17px;
    color: #ffffff;
}
.img_max {
    max-height: 120px;
    max-width: 120px;
}

@media screen and (max-width: 450px) {
    .logout {
        width: 100%;
    }
}
