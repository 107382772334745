.baseinput {
    border: 1px solid #e5e5e5;
    background-color: var(--white-color);
}

.successInput {
    border: 1px solid var(--dark-text);
    background-color: rgba(0, 0, 0, 0.03);
}
.errorInput {
    border: 1px solid var(--error);
    background-color: var(--error-bg);
}
.baseinput input,
.errorInput input,
.successInput input {
    caret-color: var(--secondary-color);
    color: var(--light-text);
}

.errorInput input::placeholder {
    color: var(--dark-text) !important;
}
.alertIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--error);
}
