.title_section {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #162142;
    margin-bottom: 0;
}
.subtitle_function {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #5d6e82;
}

.avatar {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #7730f6;
    background-position: center;
    background-size: contain;
    margin-left: 25px;
    background-repeat: no-repeat;
}
.avatarPlaceholder {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: #7730f6;
    background-position: center;
    background-size: 25px;
    margin-left: 25px;
    background-repeat: no-repeat;
}
.avatar_child_1 {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: red;
    background-position: center;
    background-size: cover;
    margin-left: 55px;
}
.title_member {
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #162142;
    display: flex;
    align-items: center;
}
.username_member {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #7730f6;
}
.id_member {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #a6b2be;
}

.text_icon_plus {
    color: #a8cdff;
    border: 0.707401px solid #a8cdff;
    border-radius: 2.1222px;
    padding: 2px;
    cursor: pointer;
    height: 15px;
    width: 15px;
    position: relative;
    display: block;
    z-index: 9;
    background-color: white;
}
.icon_circle {
    color: #a8cdff;
    border-radius: 2.1222px;
    padding: 2px;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle_final {
    border-radius: 50%;
    background-color: #a8cdff;
    height: 10px;
    width: 10px;
}
.text_icon_plus_child {
    color: #a8cdff;
    border: 0.707401px solid #a8cdff;
    background-color: white;
    border-radius: 2.1222px;
    padding: 2px;
    cursor: pointer;
    opacity: 0;
    position: relative;
    z-index: -1;
}
.button_toggle {
    color: #a8cdff;
    background-color: white;
    border: none;
    outline: none;
    position: absolute;
    top: 100px;
    left: 25px;
    font-size: 15px;
    border: 0.707401px solid #a8cdff;
    padding: 0 5px;
    border-radius: 2.1222px;
    cursor: pointer;
}
.plus_border,
.plus_border_first {
    position: relative;
    margin-left: 20px;
    background-color: white;
}

.coins,
.personal_poll,
.ratio,
.member,
.status,
.active_pools,
.member_since {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1e1f25;
}
.coins img {
    width: 50px;
}
.coins,
.personal_poll,
.ratio,
.status,
.member,
.active_pools,
.member_since {
    min-width: 200px;
}
.coins {
    min-width: 100px;
}
.status {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: center;
}

.dashed_last {
    position: absolute;
    top: 2px;
    left: -26px;
    width: 35px;
    border-top: 1px dashed #a8ceff;
}
.dashed_last_dot {
    width: 21px;
    background-color: red;
    height: 21px;
}

.vertical_left {
    position: absolute;
    top: -24px;
    left: -65px;
    transform: rotate(90deg);
    border-top: 1px dashed #a8ceff;
    width: 69px;
}
.vertical_left_initial {
    position: absolute;
    top: -25px;
    left: -66px;
    transform: rotate(90deg);
    border-top: 1px dashed #a8ceff;
    width: 71px;
}
.orizontal {
    position: absolute;
    top: 10px;
    left: -31px;
    width: 40px;
    border-top: 1px dashed #a8ceff;
}
.orizontal_circle {
    position: absolute;
    top: 10px;
    left: -31px;
    width: 40px;
    border-top: 1px dashed #a8ceff;
}
.pre_line {
    position: absolute;
    top: -25px;
    transform: rotate(90deg);
    border-top: 1px dashed #a8ceff;
    width: 71px;
}
.pre_line_last {
    position: absolute;
    top: -25px;
    transform: rotate(90deg);
    border-top: 1px dashed #a8ceff;
    width: 71px;
}
.container_max {
    width: max-content;
    background-color: white;
    padding: 20px 0 25px;
}
.list_member > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    padding: 0 0 25px;
}

.button_more_folder {
    font-style: normal;
    font-weight: 400;
    background-color: white;
    height: 20px;
    width: 20px;
    font-size: 12.32px;
    line-height: 18px;
    color: #5a626b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
    margin-left: 7px;
}
.button_more_folder:hover {
    background-color: #f2f4f8;
}

.setting_popup_folder {
    position: absolute;
    right: -215px;
    top: -60px;
    padding: 12px 19px 5px;
    width: 200px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}

.setting_popup_folder h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #748194;
}
.setting_popup_folder p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #5d6e82;
}
.setting_popup_folder p:hover {
    cursor: pointer;
    color: #7730f6;
}
