.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.label_box {
    display: flex;
    align-items: center;
}
.col_form_label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14.18px;
    line-height: 18px;
    padding: 10px 5px;
}
.col_form_label_p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14.18px;
    line-height: 18px;
}
.form_control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form_control {
    display: block;
    width: 100%;
    padding: 9px 0.75rem;
    padding-right: 35px;
    color: #1e2022;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.751391px solid #eef0f5;
    appearance: none;
    border-radius: 3.75696px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.position_button {
    display: flex;
    justify-content: flex-end;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 15px;
}
.visibility {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.password_efficency {
    height: 5px;
    width: 1%;
    margin-top: 9px;
    border-radius: 22px;
    margin-bottom: 7px;
    transition: all 0.7s ease;
}
.password_info_text {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #a0a3a7;
    margin-bottom: 0;
}
.requiremend_password {
    font-weight: 400;
    font-size: 12.18px;
    line-height: 18px;
    color: #5d6e82;
    margin-bottom: 5px;
}
