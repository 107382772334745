.list_menu {
    background: #ffffff;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
    border-radius: 9.67585px;
    padding: 20px 0;
    transition: all 0.5s;
    position: sticky;
    top: 0;
}
.list_menu svg {
    margin-left: 20px;
    fill: #5d6e82;
}
.list_menu svg path {
    fill: #5d6e82;
}
.list_menu ul {
    list-style: none;
    padding: 0;
}
.list_menu ul li {
    margin-bottom: 13px;
}
.list_menu ul li span {
    color: #1e1f25;
    font-weight: 500;
    font-size: 15.5462px;
    line-height: 20px;
    margin: 0px 9px;
}
.display_menu_mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 18px;
    background: #ffffff;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
    border-radius: 9.67585px;
    margin-bottom: 20px;
    display: none;
}
.display_menu_mobile:hover {
    box-shadow: 0 3px 6px -2px rgba(140, 152, 164, 0.25);
}
.resp_max_menu {
    max-width: 400px;
}

@media screen and (max-width: 991px) {
    .display_menu_mobile {
        display: flex;
    }
    .list_menu {
        height: 0;
        overflow: hidden;
        padding: 0;
    }
    .list_menu.active {
        height: 350px;
        padding: 20px 0;
    }
    .resp_max_menu {
        max-width: 100%;
    }
}
