.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.resetPassword {
    width: 100%;
    width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px 20px 10px;
}
.header {
    text-align: center;
}

.button {
    margin-top: 10px;
}

.visibility {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.password_efficency {
    height: 5px;
    width: 1%;
    margin-top: 9px;
    border-radius: 22px;
    margin-bottom: 7px;
    transition: all 0.7s ease;
}
.password_info_text {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #a0a3a7;
}
.info_icon {
    color: #5d6e82;
    font-size: 11px;
    font-weight: 800;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #5d6e82;
    opacity: 0.6;
    cursor: pointer;
    margin-left: 5px;
}
.popup_password {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 2;
    color: white;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    width: 235px;
    display: none;
}
.popup_password_text {
    background-color: #162142;
    border-radius: 14px;
    padding: 5px 10px;
}
.popup_password_text:before {
    content: "";
    display: inline-block;
    width: 15px;
    transform: rotate(45deg);
    height: 15px;
    position: absolute;
    top: 19px;
    left: -4px;
    z-index: -1;
    background-color: #162142;
}
.inputVoid {
    border-bottom: none !important;
}
.inputLow {
    border-bottom: 5px solid #ea4479 !important;
}
.inputMedium {
    border-bottom: 5px solid #f0dc57 !important;
}
.inputHigh {
    border-bottom: 5px solid #55add9 !important;
}
.inputStrong {
    border-bottom: 5px solid #5bc142 !important;
}

.error {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--error);
}

@media screen and (max-width: 460px) {
    .resetPassword {
        width: 100%;
    }
}
