.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.authCode {
    width: 100%;
    max-width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px 0;
}
.header {
    text-align: center;
}
.header img {
    width: 100%;
}
.header p {
    padding: 0 30px;
}
.error {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--error);
}
.success {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--success);
}

.buttonBox {
    text-align: center;
}
.button {
    max-width: 340px;
    margin-inline: auto;
}
.resendCode {
    margin-top: 10px;
}
.resendCode span {
    cursor: pointer;
}

.inputTwoFactor div {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 10px;
}
.inputTwoFactor input {
    width: 45px;
    height: 45px;
    padding: 0;
    font-weight: 600;
    font-size: 24px;
    margin: 5px 6px 0;
    text-align: center;
    text-transform: uppercase;
    color: #494949;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    background: var(--white-color);
    background-clip: border-box;
    background-clip: padding-box;
}

@media screen and (max-width: 463px) {
    .inputTwoFactor input {
        width: 40px;
        height: 45px;
    }
}
@media screen and (max-width: 333px) {
    .inputTwoFactor input {
        width: 37px;
        height: 40px;
    }
}
