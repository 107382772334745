.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.label_box {
    display: flex;
    align-items: center;
}
.col_form_label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14.18px;
    line-height: 18px;
    padding: 10px 5px;
}
.form_control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form_control {
    display: block;
    width: 100%;
    padding: 9px 0.75rem;
    color: #1e2022;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.751391px solid #eef0f5;
    appearance: none;
    border-radius: 3.75696px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.form_control_new:focus {
    color: none;
    background-color: #fff;
    border-color: none;
    outline: 0;
    box-shadow: none;
}
.form_control_new {
    display: block;
    width: 100%;
    padding: 9px 0.75rem;
    color: #1e2022;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    background-clip: padding-box;
    border: none !important;
    appearance: none;
    border-radius: 3.75696px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.remember_billing {
    font-weight: 500;
    font-size: 12.9086px;
    line-height: 12px;
    color: #5d6e82;
}
.optional_set {
    color: #c4c4c4;
}
.position_button {
    display: flex;
    justify-content: flex-end;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px 0px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 15px;
    transition: width 0.3s;
    position: relative;
    width: 170px;
    height: 40px;
}
.button span {
    display: block;
    width: 100%;
}
.button * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;
}
.saved_text {
    opacity: 0;
    /* transition-delay: 0.3s; */
    transition: 0.1s;
}
