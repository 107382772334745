.referral_table {
    margin-bottom: 100px;
}
.referral_table h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #162142;
    margin-bottom: 20px;
}
.filter_table button {
    border: none;
    margin-right: 10px;
    margin-bottom: 8px;
    background: #ffffff;
    border-radius: 11px;
    padding: 8px 18px 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #162142;
}
.filter_table {
    border-radius: 11px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #162142;
    margin-bottom: 30px;
}
.filter_table_text {
    background: #ffffff;
    font-size: 16px;
    line-height: 24px;
    color: #162142;
    font-weight: 600;
    padding: 10px 18px 10px;
    margin-top: 20px;
    border-radius: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.filter_table p {
    margin-bottom: 0;
}
.export_button {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.export_button:hover {
    color: #7730f6;
}
