.qr_code {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(195.2deg, #162142 2.23%, #620659 94.81%);
    border-radius: 10px;
    padding: 15px 25px 30px;
    z-index: 99;
    width: 320px;
    display: none;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
}
.close_button {
    position: absolute;
    top: 22px;
    right: 25px;
    cursor: pointer;
}
.close_button img {
    width: 17px;
}
.logo {
    width: 100px;
}
.text {
    margin: 3px 0 12px;
    font-weight: 500;
    font-size: 17px;
    line-height: 160%;
    color: #ffffff;
    text-align: center;
}
.body_qr {
    background-color: white;
    border-radius: 12px;
}
.qrcode_img {
    width: 100%;
    border-radius: 12px;
}
.code_referral {
    font-weight: 600;
    font-size: 18.5279px;
    line-height: 160%;
    color: #142747;
}
.referral_id {
    font-weight: 600;
    font-size: 18.5279px;
    line-height: 160%;
    color: #142747;
}
