.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.forgotPassword {
    width: 100%;
    max-width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px;
}
.header {
    text-align: center;
    margin-bottom: 20px;
}
.backLink {
    text-align: center;
    margin-top: 4px;
}
.backLink svg {
    color: var(--main-color);
    transition: all 0.3s ease;
}
.backLink:hover svg {
    color: var(--main-bg-color);
}
.error {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--error);
}
