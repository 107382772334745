.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 900;
}
.viewportLayover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
}
.shape {
    position: absolute;
}

.loader img.shape:nth-child(1) {
    max-width: 60%;
    bottom: 0;
    left: -5%;
    animation: moveShapes 3s ease-in-out infinite;
}
.loader img.shape:nth-child(2) {
    max-width: 30%;
    top: -10%;
    right: -5%;
    animation: moveShapes 5s ease-in-out infinite;
}
.loader img.shape:nth-child(3) {
    max-width: 40%;
    top: -20%;
    left: 0;
    animation: moveShapes 3s ease-in-out infinite;
}
.loader img.shape:nth-child(4) {
    max-width: 20%;
    top: 10%;
    left: 10%;
    animation: moveShapes 4s ease-in-out infinite;
}
.loader img.shape:nth-child(5) {
    max-width: 20%;
    bottom: 15%;
    right: 15%;
    animation: moveShapes 5s ease-in-out infinite;
}
