.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.registrationCompany {
    width: 100%;
    max-width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px;
}
.separator_border {
    margin: 20px 0;
    border-top: 1px solid #c8c9cc;
    flex-grow: 2;
}
.forgot_password_box {
    display: flex;
    justify-content: space-between;
}
.remember {
    color: #5d6e82;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
}
.visibility {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.form_control {
    display: block;
    width: 100px;
    padding: 0.375rem 0.75rem;
    padding-right: 35px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form_control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.password_efficency {
    height: 5px;
    width: 1%;
    margin-top: 9px;
    border-radius: 22px;
    margin-bottom: 7px;
    transition: all 0.7s ease;
}
.password_info_text {
    text-align: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: #a0a3a7;
}
.info_icon {
    color: #5d6e82;
    font-size: 11px;
    font-weight: 800;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #5d6e82;
    opacity: 0.6;
    cursor: pointer;
    margin-left: 5px;
}
.popup_password {
    position: absolute;
    top: -20px;
    right: 0;
    z-index: 2;
    color: white;
    font-weight: 600;
    font-size: 10px;
    line-height: 11px;
    width: 235px;
    display: none;
}
.popup_password_text {
    background-color: #162142;
    border-radius: 14px;
    padding: 5px 10px;
}
.popup_password_text p {
    color: white;
    font-size: 12px;
}
.popup_password_text:before {
    content: "";
    display: inline-block;
    width: 15px;
    transform: rotate(45deg);
    height: 15px;
    position: absolute;
    top: 19px;
    left: -4px;
    z-index: -1;
    background-color: #162142;
}

@media screen and (max-width: 450px) {
    .popup_password {
        top: 26px;
        width: 90%;
        left: 0px;
    }
    .popup_password_text:before {
        top: -4px;
        left: 80px;
    }
}
