.cover_profile {
    background: #ffffff;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 24px;
    position: relative;
}
.cover_image {
    position: relative;
}
.cover_image .banner {
    width: 100%;
    border-radius: 13px;
    height: 120px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.circle_photo {
    height: 80px;
    width: 80px;
    background-position: center;
    background-color: #6a7786;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    border: 3px solid #ffffff;
    cursor: pointer;
}
.button_upload {
    background: #ffffff;
    border-radius: 4.20455px;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    color: #6a7786;
    position: absolute;
    cursor: pointer;
    bottom: 10px;
    right: 10px;
    display: flex;
    align-items: center;
}
.button_upload:hover {
    color: #7730f6;
}
.change_photo {
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    right: -5px;
    color: #6a7786;
    text-align: center;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
    box-shadow: 1px 1px 2.23529px rgba(0, 0, 0, 0.15);
}
.circle_photo:hover .change_photo {
    background-color: #7730f6;
    color: white;
}
.name_profile {
    font-weight: 700;
    font-size: 17.128px;
    line-height: 7px;
    columns: #1e1f25;
    padding-top: 18px;
}
.information_account {
    font-weight: 600;
    font-size: 12.42714px;
    line-height: 8px;
    margin-top: 15px;
}
.information_account span {
    color: #a6b2be;
}
span.nickname {
    color: #7730f6;
}
.information_photo_visible {
    font-style: normal;
    font-weight: 600;
    font-size: 16.02px;
    line-height: 18px;
    columns: #1e1f25;
    margin-top: 30px;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px 20px;
    color: white;
    font-weight: 500;
    font-size: 12.7247px;
    line-height: 17px;
    border: none;
}
