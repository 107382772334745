.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.survey {
    width: 100%;
    max-width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    /* height: 100%;
    max-height: 405px; */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px;
}
.h1_registration {
    font-weight: 700;
    font-size: 18px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 15px;
}
.p_registration {
    color: #748194;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 5px;
}
.img {
    margin-right: 10px;
}

.sign_in_button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 100%;
    margin-top: 15px;
}
.remember {
    font-style: normal;
    font-weight: 500;
    font-size: 11.9086px;
    line-height: 12px;
    color: #5d6e82;
}
.question {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #0b1828;
    margin-bottom: 13px;
    margin-top: 23px;
}

.form_control {
    display: block;
    resize: none;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #b6c2d242;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form_control::placeholder {
    font-weight: 400;
    font-size: 14.6568px;
    color: #b6c2d2;
}
.form_control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.number_char {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: #b6c2d2;
}
