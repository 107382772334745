/* Contract */
.box_sign {
    margin: 20px 0;
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    max-width: 700px;
    margin-inline: auto;
}

.sign_text {
    font-weight: 600;
    font-size: 15px;
    color: #162142;
}

.box_sign .column:not(:last-child) .single_fil {
    border-bottom: 0.903993px solid #eef0f5;
}

.box_sign .single_file {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    color: #1e1f25;
    word-break: break-all;
}
.box_sign .single_file .information {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #6a7786;
    display: block;
    margin-left: 3px;
}
.download_contract a {
    color: #162142;
}
.download_contract a:hover {
    cursor: pointer;
    color: #7730f6;
}
.download_contract svg {
    height: 22px;
}
.miniReaderContainer {
    height: 600px;
    background-color: #ddd;
    border: 1px solid black;
}
.miniReaderContent {
    height: 100%;
    width: 100%;
}
.label_sign {
    font-weight: 400;
    font-size: 11.9086px;
    line-height: 12px;
    color: #b6c2d2;
    margin-bottom: 0;
    margin-left: 8px;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 90%;
    margin-top: 15px;
}
.box_allow {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    width: 450px;
    margin-inline: auto;
}

.box_allow h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 17px;
}
.box_allow p {
    color: #5d6e82;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}
.box_allow h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 17px;
}

.center_div {
    width: 100%;
    min-height: 100vh;
    padding: 40px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sign_in_button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 100%;
    margin-top: 15px;
}

.lockBox {
    margin-bottom: 50px;
    text-align: center;
}
.lockBox img {
    max-height: 180px;
}
.lockBox h3 {
    font-weight: 700;
    font-size: 23px;
    margin-bottom: 17px;
    margin-bottom: 0;
    line-height: 40px;
    max-width: 600px;
    margin: 0 auto;
}
@media screen and (max-width: 700px) {
    .box_step {
        margin-right: 0;
        width: 50px;
        margin: 0 auto 10px;
    }
}
@media screen and (max-width: 450px) {
    .box_allow {
        max-width: 94%;
        padding: 30px 25px;
    }
}
