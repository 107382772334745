.position_kyc {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.center_popup {
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
