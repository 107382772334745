.header_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 25px;
    padding-right: 32px;
    position: relative;
}
.section_profile {
    display: flex;
    align-items: center;
    position: relative;
}
.profile_icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.status_account {
    position: absolute;
    width: 11px;
    height: 11px;
    right: -2px;
    bottom: -3px;
    border-radius: 50%;
    background: #00d27b;
}
.header_conversion_value {
    display: flex;
    align-items: center;
}
.header_values {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 13px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #edf2f8;
}
.header_value {
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
}
.hambuger_menu {
    color: #edf2f8;
    margin-right: 15px;
    display: none;
    cursor: pointer;
    font-size: 18px;
    padding-left: 32px;
}
.setting_popup_qrcode {
    position: absolute;
    right: 0;
    top: -50px;
    width: 300px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup_header {
    position: absolute;
    right: 0;
    top: -50px;
    padding: 12px 19px;
    width: 187px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup_header h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #748194;
    margin-bottom: 1.5rem !important;
}
.setting_popup_header p {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #5d6e82;
    cursor: pointer;
}
.setting_popup_header p:hover {
    color: #2b0078;
}
.notification {
    color: #edf2f8;
    margin: 0 15px;
    height: 24px;
    cursor: pointer;
}
.links {
    color: #edf2f8;
    height: 19px;
    cursor: pointer;
}
.new_notify {
    position: relative;
    margin-top: 8px;
}
.qr_code {
    background: linear-gradient(195.2deg, #162142 2.23%, #620659 94.81%);
    border-radius: 10px;
    padding: 15px 25px 30px;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
}
.text {
    margin: 3px 0 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 160%;
    color: #ffffff;
}
.text_body {
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    opacity: 0.7;
    margin: 0;
}
.link_text {
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    opacity: 0.9;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.body_qr {
    background-color: white;
    border-radius: 12px;
    padding: 10px;
    margin-top: 15px;
}
.qrcode_img {
    width: 100%;
    border-radius: 12px;
}
.code_referral {
    font-weight: 600;
    font-size: 18.5279px;
    line-height: 160%;
    color: #142747;
}
.referral_id {
    font-weight: 600;
    font-size: 18.5279px;
    line-height: 160%;
    color: #142747;
}
.button_copy {
    font-weight: 500;
    font-size: 17px;
    line-height: 160%;
    color: #ffffff;
    text-align: center;
    background-color: #620659;
    border-radius: 10px;
    padding: 10px 0;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
}
.button_copy:hover {
    background-color: #5009cf;
}

@media screen and (max-width: 1200px) {
    .hambuger_menu {
        display: block;
    }
}
@media screen and (max-width: 590px) {
    .header_conversion_value {
        display: none;
    }
}
