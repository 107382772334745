.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.terms {
    width: 100%;
    max-width: 700px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px;
}
.card {
    max-height: 500px;
    overflow-y: scroll;
}
.terms h1 {
    font-weight: 600;
    font-size: 36.9778px;
    line-height: 55px;
    color: #162142;
}
.terms ul {
    list-style: none;
}
.terms li {
    padding-bottom: 10px;
}
.terms h4,
.terms h6,
.terms h3 {
    font-weight: 600;
}
.terms h3 {
    text-transform: uppercase;
    font-size: 22px;
}
.terms ul.index {
    list-style: decimal;
}
.terms ul.index li {
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    padding-bottom: 5px;
    font-size: 15px;
}
.terms ul.index li a {
    color: #162142;
}
.terms ul.index li:hover a {
    color: #620659;
}
.letterList {
    list-style: upper-alpha !important;
}
.letterList li {
    padding-bottom: 10px;
}
.sign_in_button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 100%;
    margin-top: 15px;
}
.footerBox {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerBox div {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.503);
}

.scollIcon svg {
    font-size: 18px;
    cursor: pointer;
    padding: 0 10px;
}
.scollIcon svg:hover {
    color: #7730f6;
}

/* Contract */
.box_sign {
    margin: 20px 0;
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    max-width: 700px;
}

.sign_text {
    font-weight: 600;
    font-size: 15px;
    color: #162142;
}

.box_sign .column:not(:last-child) .single_fil {
    border-bottom: 0.903993px solid #eef0f5;
}

.box_sign .single_file {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    color: #1e1f25;
    word-break: break-all;
}
.box_sign .single_file .information {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #6a7786;
    display: block;
    margin-left: 3px;
}
.download_contract a {
    color: #162142;
}
.download_contract a:hover {
    cursor: pointer;
    color: #7730f6;
}
.download_contract svg {
    height: 22px;
}
.miniReaderContainer {
    height: 300px;
    background-color: #ddd;
    border: 1px solid black;
}
.miniReaderContent {
    height: 100%;
    width: 100%;
}
.label_sign {
    font-weight: 400;
    font-size: 11.9086px;
    line-height: 12px;
    color: #b6c2d2;
    margin-bottom: 0;
    margin-left: 8px;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 90%;
    margin-top: 15px;
}
.box_allow {
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 30px 45px;
    width: 450px;
}

.box_allow h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 17px;
}
.box_allow p {
    color: #5d6e82;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
}
.box_allow h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 16px;
    margin-bottom: 17px;
}
@media screen and (max-width: 650px) {
    .footerBox {
        display: block;
    }
    .goDown {
        display: block;
        text-align: center;
    }
}
@media screen and (max-width: 500px) {
    .sign_text {
        font-size: 13px;
    }
    .box_sign {
        padding: 50px 10px;
    }
}
