.qr_code_background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 10px;
    padding: 45px 25px 50px;
    z-index: 99;
    max-width: 620px;
    display: none;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
}

.close_button {
    position: absolute;
    top: 22px;
    right: 25px;
    cursor: pointer;
}
.close_button img {
    width: 17px;
}
.set_dimension {
    border: 1px solid #c4c4c4;
    border-radius: 30px;
    padding: 13px 18px;
    cursor: pointer;
}
.circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    border: 1px solid #c4c4c4;
    justify-content: center;
    align-items: center;
}
.circle svg {
    cursor: pointer;
    font-size: 25px;
}
svg.twitter path {
    fill: #4fbaed;
}
svg.facebook path {
    fill: #1877f2;
}
svg.telegram path {
    fill: #5ab4e2;
}
svg.discord path {
    fill: #8c9eff;
}
svg.whatsapp path {
    fill: #68be44;
}
svg.link path {
    fill: #8c39ab;
}
svg.download path {
    fill: #162142;
}
.container_banner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
}
.social_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
}

@media screen and (max-width: 620px) {
    .container_banner {
        flex-wrap: wrap;
        flex-direction: column;
    }
    .social_box {
        justify-content: center;
        flex-wrap: wrap;
    }
}
