.title_section {
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    color: #162142;
}
.subtitle_function {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: #5d6e82;
}
.eye {
    background: #ffffff;
    border-radius: 5px;
    padding: 0 5px;
    cursor: pointer;
    margin-left: 5px;
}
.eye img {
    width: 18px;
}

.row_stat {
    text-align: center;
    background-color: white;
    border-radius: 10px;
    margin-top: 20px;
}
.column_stat {
    padding: 30px 10px;
}
.column_stat .box_icon {
    margin-bottom: 20px;
}

.stat_title {
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #162142;
    text-transform: uppercase;
}
.stat_number {
    font-weight: 600;
    font-size: 32px;
    line-height: 60px;
    color: #162142;
    display: flex;
    align-items: center;
    justify-content: center;
}
.increment_stat {
    display: flex;
    justify-content: center;
}
.separator {
    width: 1px;
    background: #e6e6e6;
    margin: 0 8px;
}
.chart_section {
    text-align: right;
}
.chart_section_week {
    text-align: left;
}
.chart {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #00d27b;
}
.chart_week {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #162142;
}
.chart_week img {
    vertical-align: bottom;
    margin-left: 1px;
}
.subtitle_chart {
    font-weight: 600;
    font-size: 7px;
    line-height: 10px;
    color: #162142;
}

.section_rank_member {
    background-color: white;
    margin-top: 22px;
    padding: 18px 5px;
    border-radius: 10px;
}
.rank_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #162142;
}
.rank_coin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.rank_coin .box_rank:not(:last-child) {
    border-right: 0.71px solid #c7ccd3;
}
.box_rank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: calc(100% / 5);
}
.box_rank img {
    height: 50px;
}
.rank_text {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #162142;
}
.rank_number {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #162142;
}
.box_referral_title {
    font-weight: 600;
    font-size: 16.07px;
    line-height: 24px;
    color: #162142;
    padding-left: 12px;
}
.box_referral_link {
    background: linear-gradient(0deg, #ffffff, #ffffff), #edf2f8;
    border-radius: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #162142;
    padding: 8px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.box_referral_link svg {
    color: #e6e6e6;
    font-size: 20px;
    cursor: pointer;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99;
    bottom: 140%;
    left: 1px;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 60%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 676px) {
    .box_rank {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-basis: calc(100% / 2);
        margin: 10px 0;
    }
    .rank_coin .box_rank:not(:last-child) {
        border-right: none;
    }
}
