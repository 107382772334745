.card_profile {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    padding: 24px;
}
.card_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #1e2022;
    margin-bottom: 25px;
}
.card_title::after {
    border-bottom: 1px solid #d4d4d4;
    width: 100%;
    display: block;
    padding-bottom: 10px;
    content: "";
}
.label_box {
    display: flex;
    align-items: center;
}
.col_form_label {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14.18px;
    line-height: 18px;
    padding: 10px 5px;
}
.form_control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: rgba(140, 152, 164, 0.25);
    outline: 0;
    box-shadow: 0 0 1rem 0 rgba(140, 152, 164, 0.25);
}
.form_control {
    display: block;
    width: 100%;
    padding: 9px 0.75rem;
    padding-right: 35px;
    color: #1e2022;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    background-clip: padding-box;
    border: 0.751391px solid #eef0f5;
    appearance: none;
    border-radius: 3.75696px;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.position_button {
    display: flex;
    justify-content: flex-end;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px 0px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    margin-top: 15px;
    transition: width 0.3s;
    position: relative;
    width: 170px;
    height: 40px;
}
.button span {
    display: block;
    width: 100%;
}
.button * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;
}
.saved_text {
    opacity: 0;
    /* transition-delay: 0.3s; */
    transition: 0.1s;
}
.label_checkbox {
    width: 100%;
    margin-bottom: 1.5rem;
}
.col_switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.col_label p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14.18px;
    padding: 0 5px;
}
.col_label p:last-child {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14.18px;
    color: #5d6e82;
    padding: 0 5px;
}
.form_switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
}
.form_switch i {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 46px;
    height: 26px;
    background-color: #e6e6e6;
    border-radius: 23px;
    vertical-align: text-bottom;
    transition: all 0.3s linear;
}
.form_switch i::before {
    content: "";
    position: absolute;
    left: 0;
    width: 42px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
    transition: all 0.25s linear;
}
.form_switch i::after {
    content: "";
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate3d(2px, 2px, 0);
    transition: all 0.2s ease-in-out;
}
.form_switch:active i::after {
    width: 28px;
    transform: translate3d(2px, 2px, 0);
}
.form_switch:active input:checked + i::after {
    transform: translate3d(16px, 2px, 0);
}
.form_switch input {
    display: none;
}
.form_switch input:checked + i {
    background-color: #4bd763;
}
.form_switch input:checked + i::before {
    transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.form_switch input:checked + i::after {
    transform: translate3d(22px, 2px, 0);
}
.option_gtm {
    font-size: 11px;
}
