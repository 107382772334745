.referral_setting {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 25px 90px;
    z-index: 99;
    width: 95%;
    display: none;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
}
.layover_add_ref {
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    border-radius: 10px;
}
.title_setting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5d6e82;
}
.title_setting h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
}
.title_setting p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}
.title_setting button {
    border: none;
    background: #7730f6;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08),
        0px 1px 1.5px rgba(0, 0, 0, 0.07), 0px 2px 5px rgba(43, 45, 80, 0.08),
        0px 0px 0px 1px rgba(43, 45, 80, 0.1);
    border-radius: 3px;
    color: #ffffff;
    padding: 5px 13px;
}
.button_setting_table {
    position: fixed;
    right: 20px;
}
.boxButtonReferralLeft {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
}
.boxReferralButtons {
    display: flex;
    justify-content: center;
}
/*+ Confirm creation referral  */
.confirmButtonReferral {
    padding: 5px 13px;
    border-radius: 3px;
    width: 100px;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_setting_table button,
.button_setting_table_add button,
.boxReferralButtons button,
.boxButtonReferralLeft button {
    padding: 5px 13px;
    border-radius: 3px;
    font-weight: 500;
    border: none;
}
.button_setting_table button:nth-child(1),
.button_setting_table_add button:nth-child(1),
.boxReferralButtons button:nth-child(1) {
    color: #5d6e82;
    background: #dfe2e4;
}
.button_setting_table button:nth-child(2),
.button_setting_table_add button:nth-child(2),
.boxReferralButtons button:nth-child(2) {
    color: white;
    background: #7730f6;
}
.boxReferralButtons button,
.boxButtonReferralLeft button {
    margin: 0 5px;
}
.button_setting_table_add button {
    margin-left: 8px;
}
.close_button {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
}
.close_button_improved {
    position: absolute;
    top: 15px;
    right: 12px;
    cursor: pointer;
}
.box_add_ref {
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 25px 40px;
    border-radius: 10px;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
    z-index: 101;
}
.box_add_ref h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #162142;
}
.box_add_ref p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #162142;
}
.box_add_ref label {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #162142;
}
.box_add_ref span {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #b6c2d2;
}

.set_ratio {
    display: flex;
    justify-content: space-between;
}
.set_ratio label {
    color: #b6c2d2;
    margin-bottom: 0;
}
.set_ratio input {
    width: 90px;
}
.layover_box_add_ref {
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    border-radius: 10px;
    display: none;
}
.confirm_new_referral {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 40px 15px;
    max-width: 320px;
    min-width: 320px;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: none;
}
.list_popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 25px 15px;
    width: 500px;
    box-shadow: 0px 0px 3.87034px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: none;
}

.confirm_new_referral p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 10px;
}
.layover_default {
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    border-radius: 10px;
    display: none;
}
.note_icon {
    cursor: pointer;
}
.note_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #162142;
    margin-bottom: 18px;
    margin-top: -10px;
}
.input_note_box {
    border-radius: 10px;
}
.input_note_box input {
    background: #eaecef;
}
.no_list_data {
    text-align: center;
}
.no_list_data img {
    margin-left: -20px;
}
.no_list_data p {
    margin-top: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 21px;
    color: #162142;
}
.range_input {
    border: none !important;
    outline: none !important;
}

.remaining_code_text {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #5d6e82;
}
.circle_not_default {
    height: 12px;
    width: 12px;
    border: 1px solid #000000;
    border-radius: 50%;
}
.circle_default {
    height: 12px;
    width: 12px;
    background: #00d27b;
    border: 1px solid #00d27b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.list_member {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #000000;
}
.list_member_minus {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #9da8ba;
}
.list_member svg {
    cursor: pointer;
}
.now_default_code {
    padding: 4px 8px;
    background: #7730f6;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #ffffff;
}
.delete_referral {
    font-weight: 400;
    font-size: 9px;
    line-height: 17px;
    color: #e31d1c;
    cursor: pointer;
}
.set_default_referral {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #c079ff;
    margin-right: 20px;
    cursor: pointer;
}
.add_new_referral_link {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #f9fafd;
}
.label_set_default {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #b6c2d2 !important;
    margin-left: 5px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 99;
    bottom: 140%;
    left: 40px;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 33%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
@media screen and (max-width: 550px) {
    .list_popup {
        width: 320px;
    }
}
@media screen and (max-width: 450px) {
    .button_setting_table button {
        font-size: 13px;
        margin-right: 0;
        margin-top: 4px;
        padding: 4px 8px;
    }
    .title_setting h2 {
        font-size: 22px;
        line-height: normal;
    }
    .title_setting button {
        font-size: 13px;
    }
}
