.sidebar_container {
    min-width: 280px;
    height: 100%;
    background-color: #2b0078;
    color: #edf2f8;
    padding-top: 9px;
}
.logo_sidebar {
    padding-left: 32px;
    padding-right: 20px;
}
.side_ul {
    padding: 0;
    list-style: none;
}
.side_ul_drop {
    padding: 0;
    list-style: none;
    border-left: 1px solid #d8e2ee;
    margin-left: 14px;
    margin-top: 8px;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
}
.side_ul_drop li {
    margin-left: 6px;
}
.side_ul_drop.active {
    height: 113px;
}
.side_ul_drop li a {
    display: flex;
    align-items: center;
    padding-left: 0px;
}
.side_ul_drop li.active {
    margin-left: 13px;
    background-color: black;
    border-radius: 5px;
}
.side_ul_drop li.active a {
    padding: 3px;
}
.side_ul_drop li .side_menu_title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.side_ul li {
    padding: 5px 10px 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.side_ul img {
    width: 14px;
    height: 14px;
    vertical-align: text-bottom;
}
.side_menu_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #edf2f8;
    vertical-align: middle;
    margin-left: 10px;
}
.etichetta {
    font-weight: 600;
    font-size: 13px;
    padding-left: 10px;
    line-height: 20px;
    text-transform: uppercase;
    margin-top: 25px;
    color: #b5b8c3;
    margin-bottom: 0;
}
.side_ul li:hover {
    background-color: rgba(55, 125, 255, 0.2);
    cursor: pointer;
    color: white;
}
.side_ul li:hover .side_menu_title {
    color: #e4d4ff;
}
.active_arrow {
    transform: rotate(-180deg);
}
.sidebar_full {
    position: absolute;
    right: -13px;
    top: 48px;
    z-index: 2;
    color: black;
    transform: rotate(-180deg);
    transition: all 0.5s;
    cursor: pointer;
    background-color: #edf2f8;
    padding: 7px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: 0 3px 6px -2px rgba(100, 102, 104, 0.65);
}

.rotate_button {
    transform: rotate(0deg);
}

.sidebar_container.deactive {
    min-width: 50px;
    max-width: 50px;
    padding: 10px 5px;
}

.sidebar_container.deactive .side_menu_title,
.sidebar_container.deactive .etichetta,
.sidebar_container.deactive .side_ul span,
.sidebar_container.deactive .side_ul svg {
    display: none;
}
.sidebar_container.deactive .side_ul {
    text-align: center;
}
.sidebar_container.deactive .side_ul img {
    width: 16px;
    height: 16px;
}
.bottom_sidebar {
    padding: 22px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px -2px 3px rgba(255, 255, 255, 0.13);
    padding-left: 30px;
    padding-right: 20px;
}
.button_effect {
    cursor: pointer;
}
.button_effect:hover {
    color: #9a61fc;
}

.setting_popup_sidebar_center {
    position: absolute;
    left: -99px;
    bottom: 150px;
    padding: 12px 19px;
    width: 220px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup_sidebar_mobile {
    position: absolute;
    right: 0;
    bottom: 150px;
    padding: 12px 19px;
    width: 220px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup_sidebar {
    position: absolute;
    left: 0;
    bottom: 150px;
    padding: 12px 19px;
    width: 220px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup_sidebar h2,
.setting_popup_sidebar_mobile h2,
.setting_popup_sidebar_center h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #748194;
    margin-bottom: 1.5rem !important;
}
.setting_popup_sidebar p,
.setting_popup_sidebar_mobile p,
.setting_popup_sidebar_center p {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #5d6e82;
    cursor: pointer;
}
.setting_popup_sidebar p:hover,
.setting_popup_sidebar_mobile p:hover,
.setting_popup_sidebar_center p:hover {
    color: #2b0078;
}
