.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.logout {
    width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px;
}

.card {
    text-align: center;
}
.card img {
    margin-bottom: 10px;
    width: 135px;
    height: 116px;
}
.card p {
    margin-bottom: 20px;
}

.button {
    width: 100%;
    text-align: center;
    background-color: var(--main-color);
    border-radius: 3px;
    padding: 10px;
    color: var(--white-color);
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
    transition: all 0.3s ease;
}

.button:hover {
    background-color: var(--main-bg-color);
}

@media screen and (max-width: 450px) {
    .logout {
        width: 100%;
    }
}
