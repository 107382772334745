.viewport {
    min-height: 100vh;
    width: 100%;
    background-color: var(--main-bg-color);
    position: relative;
    margin-inline: auto;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*+ Text size*/
.viewport h1,
.viewport h2,
.viewport h3,
.viewport h4,
.viewport h5,
.viewport h6 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
    margin-bottom: 10px;
}
.viewport input {
    font-weight: 400;
    font-size: 16px;
}
.viewport a {
    font-weight: 400;
    font-size: 14px;
    color: var(--main-color);
    text-decoration: none;
    outline: none;
    transition: all 0.3s ease;
}
.viewport a:hover {
    color: var(--main-bg-color);
}
.viewport p {
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0;
}
.viewport p {
    color: var(--text-auth);
}
.viewport button {
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 15px;
}
.viewport small {
    font-size: 14px;
}
.viewport label {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
    color: var(--text-auth);
}
/*+ Text size*/

.viewportLayover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(100px);
    -webkit-backdrop-filter: blur(100px);
}
.shape {
    position: absolute;
}
.viewport img.shape:nth-child(1) {
    max-width: 60%;
    bottom: 0;
    left: -5%;
    animation: moveShapes 3s ease-in-out infinite;
}
.viewport img.shape:nth-child(2) {
    max-width: 30%;
    top: -10%;
    right: -5%;
    animation: moveShapes 5s ease-in-out infinite;
}
.viewport img.shape:nth-child(3) {
    max-width: 40%;
    top: -20%;
    left: 0;
    animation: moveShapes 3s ease-in-out infinite;
}
.viewport img.shape:nth-child(4) {
    max-width: 20%;
    top: 10%;
    left: 10%;
    animation: moveShapes 4s ease-in-out infinite;
}
.viewport img.shape:nth-child(5) {
    max-width: 20%;
    bottom: 15%;
    right: 15%;
    animation: moveShapes 5s ease-in-out infinite;
}
.container {
    position: relative;
    z-index: 3;
}
.content {
    padding: 5px;
    text-align: initial;
}
.boxForm {
    padding-block: 30px;
}
@keyframes moveShapes {
    0% {
        transform: rotate(0deg) translate(0, 0);
    }
    50% {
        transform: rotate(10deg) translate(1%, 10%);
    }
    100% {
        transform: rotate(0, 0) translate(0, 0);
    }
}
