.buttonClass {
    width: 100%;
    text-align: center;
    background-color: var(--main-color);
    border-radius: 3px;
    padding: 10px;
    color: var(--white-color);
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.buttonClass:hover {
    background-color: var(--main-bg-color);
}
.buttonDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.buttonDisabled:hover {
    background-color: var(--main-color);
}
