.title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #1e1f25;
    margin-bottom: 20px;
}
.folder_box {
    margin-right: 15px;
    margin-bottom: 15px;
    width: 230px;
    background: #ffffff;
    border-radius: 8.89584px;
    padding: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1e1f25;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.folder_box_active {
    margin-right: 15px;
    margin-bottom: 13px;
    width: 230px;
    background: #ffffff;
    border-radius: 8.89584px;
    padding: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #1e1f25;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 2.62538px rgba(0, 0, 0, 0.1);
    border: 1px solid #7730f6;
}
.box_files {
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 20px 0;
}
.box_files .single_file,
.box_sign .single_file {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    color: #1e1f25;
}
.nomeCompleteFile {
    word-break: break-all;
}
.box_files .column:not(:last-child) .single_fil,
.box_sign .column:not(:last-child) .single_fil {
    border-bottom: 0.903993px solid #eef0f5;
}
.box_files .single_file .information,
.box_sign .single_file .information {
    font-weight: 400;
    font-size: 11px;
    line-height: 17px;
    color: #6a7786;
    display: block;
    margin-left: 3px;
}
.box_files img,
.box_sign img {
    height: 30px;
    margin-right: 10px;
}
.path_dir {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5d6e82;
}
.path_dir a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5d6e82;
    padding: 0 4px;
    border-radius: 3px;
}
.path_dir a:first-child {
    padding-left: 0;
}
.path_dir a.active_folder {
    background-color: white;
    margin: 0 2px;
    color: #1e1f25;
}
.button_more {
    font-style: normal;
    font-weight: 400;
    font-size: 12.32px;
    line-height: 18px;
    color: #6c7987;
    border: 1px solid #f2f4f8;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}
.button_more .icon {
    display: flex;
    align-items: center;
    padding: 8px 15px;
    transition: all 0.5s ease-in-out;
}
.button_more svg {
    vertical-align: middle !important;
    margin-left: 4px;
    transition: all 0.5s ease-in-out;
}
.setting_popup {
    position: absolute;
    right: 0;
    top: -40px;
    padding: 12px 19px;
    width: 187px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.setting_popup h2,
.setting_popup_folder h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    color: #748194;
}
.setting_popup p,
.setting_popup a,
.setting_popup_folder p {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #5d6e82;
}
.setting_popup p:hover,
.setting_popup a:hover,
.setting_popup_folder p:hover {
    cursor: pointer;
    color: #7730f6;
}

.button_more_folder {
    font-style: normal;
    font-weight: 400;
    background-color: white;
    height: 20px;
    width: 20px;
    font-size: 12.32px;
    line-height: 18px;
    color: #5a626b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 20px;
    right: 40px;
}
.button_more_folder:hover {
    background-color: #f2f4f8;
}
.setting_popup_folder {
    position: absolute;
    right: -10px;
    top: -30px;
    padding: 12px 19px;
    width: 187px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: initial;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: all 0.5s ease-in-out;
}
.sign_text {
    font-weight: 600;
    font-size: 15px;
    color: #162142;
}
.box_sign {
    margin: 20px 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 20px;
    width: 100%;
    padding: 20px;
    max-width: 700px;
    z-index: 100;
}
.miniReaderContainer {
    height: 300px;
    background-color: #ddd;
    border: 1px solid black;
}
.miniReaderContent {
    height: 100%;
    width: 100%;
}
.label_sign {
    font-weight: 400;
    font-size: 11.9086px;
    line-height: 12px;
    color: #b6c2d2;
    margin-bottom: 0;
    margin-left: 8px;
}
.button {
    background: #7730f6;
    border-radius: 3px;
    padding: 10px;
    color: white;
    font-weight: 500;
    font-size: 14.8247px;
    line-height: 17px;
    border: none;
    width: 90%;
    margin-top: 15px;
}
.download_contract a {
    color: #162142;
}
.download_contract a:hover {
    cursor: pointer;
    color: #7730f6;
}
.download_contract svg {
    height: 22px;
}
.close_button {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
}
.close_button {
    position: absolute;
    top: 17px;
    right: 12px;
    cursor: pointer;
}
.close_button img {
    height: 14px;
}
.titleLegend {
    font-size: 15px;
    font-weight: 600;
}
.legendContract {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.divisor {
    display: flex;
    align-items: center;
}
.divisor span {
    width: 80px;
}
.legendContract .description {
    font-size: 13px;
    margin-left: 10px;
    max-width: 240px;
}
.badgeAcr {
    background-color: white;
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 5px;
    border-radius: 5px;
    margin-right: 5px;
}

@media screen and (max-width: 500px) {
    .sign_text {
        font-size: 13px;
    }
    .box_sign {
        height: 500px;
        overflow-y: auto;
        padding: 50px 20px;
    }
}
