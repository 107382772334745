.logo {
    position: relative;
    max-height: 80px;
    z-index: 10;
    margin-bottom: 15px;
    margin-inline: auto;
    display: block;
}
.referral {
    width: 100%;
    max-width: 450px;
    background-color: var(--white-color);
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container {
    padding: 20px 20px 10px;
}

.header {
    text-align: center;
}
.header h2 {
    margin-bottom: 10px;
}
.applyNowButton {
    padding-block: 1px;
    margin-bottom: 20px;
    padding-inline: 10px;
    max-width: 120px;
    margin-inline: auto;
    line-height: 3px;
    background-color: transparent;
    color: var(--main-color);
}
.applyNowButtonLoading {
    padding-block: 1px;
    margin-bottom: 20px;
    padding-inline: 10px;
    width: 120px;
    margin-inline: auto;
    line-height: 3px;
    color: var(--main-color);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.applyNow {
    margin-block: 10px 20px;
    text-align: center;
}
.applyNowButtonLoading svg circle {
    fill: var(--main-color) !important;
}
.applyNowButton:hover,
.applyNowButtonLoading:hover {
    background-color: transparent;
    color: var(--bg-main-color);
}
.buttonCodeCheck {
    margin-bottom: 10px;
}
.error {
    min-height: 20px;
    padding: 1px 5px 0;
    width: 100%;
    margin-top: 3px;
    line-height: 15px;
    font-size: 13px;
    text-align: center;
    color: var(--error);
}
